var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"rigth_menu"},[_c('div',{staticClass:"rm_top"},[_vm._v(" 订单列表"),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.backBtn}},[_vm._v("返回")])],1),_c('div',{staticClass:"rm_con"},[_c('div',{staticClass:"search_wrapper clearfix"},[_c('div',{staticClass:"search_group"},[_c('label',{staticClass:"search_label"},[_vm._v("订单号")]),_c('el-input',{staticClass:"search_text",attrs:{"placeholder":"请输入订单号","clearable":""},model:{value:(_vm.searchData.id),callback:function ($$v) {_vm.$set(_vm.searchData, "id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"searchData.id"}})],1),_c('div',{staticClass:"search_group"},[_c('label',{staticClass:"search_label"},[_vm._v("下单时间")]),_c('el-date-picker',{attrs:{"type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期"},on:{"change":_vm.changeTimeFrame},model:{value:(_vm.searchData.time),callback:function ($$v) {_vm.$set(_vm.searchData, "time", $$v)},expression:"searchData.time"}})],1),_c('div',{staticClass:"search_group"},[_c('el-button',{staticClass:"search_btn",attrs:{"type":"primary"},on:{"click":_vm.checkList}},[_vm._v("查询")])],1)]),_c('div',{staticClass:"table_box"},[_c('table',{staticClass:"table_con",attrs:{"cellpadding":"0","cellspacing":"0"}},[_vm._m(0),(_vm.list.length === 0)?_c('tbody',[_vm._m(1)]):_vm._e(),_vm._l((_vm.list),function(item,index){return _c('tbody',{key:index},[_vm._m(2,true),(item.spec.type === '商城')?_c('tr',{staticClass:"tr_first",class:_vm.getStatus(item.status) === '已取消' ||
              _vm.getStatus(item.status) === '已完成' ||
              _vm.getStatus(item.status) === '已退款'
                ? 'gray_td'
                : 'blue_td'},[_c('td',{attrs:{"colspan":"8"}},[_c('div',{staticClass:"tr_first_con"},[_c('div',{staticClass:"tr_first_text"},[_vm._v(" 订单号："),_c('span',{staticClass:"tr_first_num"},[_vm._v(_vm._s(item._id.toHexString()))]),_c('span',{staticStyle:{"margin-left":"5px","color":"#1890ff","cursor":"pointer"},on:{"click":function($event){return _vm.checkOrder(item._id)}}},[_vm._v("查看")])]),_c('div',{staticClass:"tr_first_text"},[_vm._v(" 下单时间："),_c('span',{staticClass:"tr_first_num"},[_vm._v(_vm._s(_vm.getTime(item.metadata.creationTimestamp)))])])])])]):_vm._e(),(item.spec.type === '商城')?_c('tr',{staticClass:"tr_info goods_width"},[_c('td',{staticClass:"first",class:_vm.getStatus(item.status) === '已取消' ||
                _vm.getStatus(item.status) === '已完成' ||
                _vm.getStatus(item.status) === '已退款'
                  ? 'gray_td'
                  : 'blue_td',attrs:{"colspan":"2"}},[_c('div',{staticClass:"tr_info_td td_operate"},[_c('div',{class:{
                    hidden: !item.goodsMore && item.spec.items.length > 3,
                  },staticStyle:{"width":"100%"}},_vm._l((item.spec.items),function(goodsItem,goodsIndex){return _c('div',{key:goodsIndex,staticClass:"goods_list"},[_c('img',{staticClass:"goods_img",attrs:{"src":_vm.downloadUrl + goodsItem.snapshot.images[0]}}),_c('div',{staticClass:"goods_info"},[_c('div',{staticClass:"goods_name"},[_vm._v(" "+_vm._s(goodsItem.snapshot.name)+" ")]),(goodsItem.sku.name !== '默认')?_c('div',{staticClass:"goods_spec"},[_vm._v(" "+_vm._s(goodsItem.sku.name)+_vm._s(_vm.getAttributes(goodsItem.attributes))+" ")]):_vm._e()]),_c('div',{staticClass:"goods_num"},[_c('div',{staticStyle:{"margin-bottom":"5px"}},[_vm._v(" ￥"+_vm._s((goodsItem.sku.price / 100).toFixed(2))+" ")]),_c('div',[_vm._v("x"+_vm._s(goodsItem.count))])])])}),0),(item.spec.items.length > 3 && !item.goodsMore)?_c('div',{staticClass:"more_btn",on:{"click":function($event){return _vm.moreBtn(index)}}},[_vm._v(" 查看更多"),_c('i',{staticClass:"el-icon-caret-bottom"})]):_vm._e(),(item.spec.items.length > 3 && item.goodsMore)?_c('div',{staticClass:"more_btn",staticStyle:{"color":"#6d6d6d"},on:{"click":function($event){return _vm.moreBtn(index)}}},[_vm._v(" 收起"),_c('i',{staticClass:"el-icon-caret-top"})]):_vm._e()])]),_c('td',{class:_vm.getStatus(item.status) === '已取消' ||
                _vm.getStatus(item.status) === '已完成' ||
                _vm.getStatus(item.status) === '已退款'
                  ? 'gray_td'
                  : 'blue_td'},[_c('div',{staticClass:"tr_info_td td_operate"},[_c('div',{staticClass:"td_price"},[_vm._v(" ￥"),_c('span',{staticClass:"price_num"},[_vm._v(_vm._s(item.spec.amount.user / 100))])]),(
                    item.spec.amount.fullReduction.shop +
                    item.spec.amount.fullReduction.application +
                    item.spec.amount.coupon.application +
                    item.spec.amount.coupon.application
                  )?_c('div',{staticClass:"td_discount"},[_vm._v(" 已优惠￥"+_vm._s((item.spec.amount.fullReduction.shop + item.spec.amount.fullReduction.application + item.spec.amount.coupon.application + item.spec.amount.coupon.shop) / 100)+" ")]):_vm._e()])]),_c('td',{class:_vm.getStatus(item.status) === '已取消' ||
                _vm.getStatus(item.status) === '已完成' ||
                _vm.getStatus(item.status) === '已退款'
                  ? 'gray_td'
                  : 'blue_td'},[(_vm.getUserInfo(item.spec.userId))?_c('div',{staticClass:"buyer_info",on:{"click":function($event){return _vm.to_user(item.spec.userId)}}},[_vm._v(" "+_vm._s(_vm.getUserInfo(item.spec.userId))+" ")]):_vm._e(),(item.spec.deliveryInfo)?_c('div',{staticClass:"tr_info_td td_operate"},[(
                    item.spec.deliveryInfo.type !== '自取' &&
                    item.spec.deliveryInfo.type !== '团购配送' &&
                    item.spec.deliveryInfo.type !== '快递' &&
                    item.spec.deliveryInfo.info
                  )?_c('div',{staticClass:"delivery_method"},[_vm._v(" "+_vm._s(item.spec.deliveryInfo.info.contact)+" ")]):_vm._e(),(item.spec.deliveryInfo && item.spec.deliveryInfo.info)?_c('div',{staticClass:"delivery_text"},[_vm._v(" "+_vm._s(item.spec.deliveryInfo.info.phone)+" ")]):_vm._e(),(item.spec.deliveryInfo.phone)?_c('div',{staticClass:"delivery_text"},[_vm._v(" "+_vm._s(item.spec.deliveryInfo.phone)+" ")]):_vm._e(),(item.spec.deliveryInfo.type === '快递')?_c('div',{staticClass:"delivery_text"},[_vm._v(" "+_vm._s(item.spec.deliveryInfo.name)+" ")]):_vm._e()]):_vm._e()]),_c('td',{class:_vm.getStatus(item.status) === '已取消' ||
                _vm.getStatus(item.status) === '已完成' ||
                _vm.getStatus(item.status) === '已退款'
                  ? 'gray_td'
                  : 'blue_td'},[_c('div',{staticClass:"tr_info_td td_status"},[_c('span',{staticClass:"status_circle",style:({
                    background:
                      _vm.getStatus(item.status) === '待收货'
                        ? '#FFB300'
                        : _vm.getStatus(item.status) === '已完成'
                        ? '#11A983'
                        : _vm.getStatus(item.status) === '已取消'
                        ? '#b6b6b6'
                        : _vm.getStatus(item.status) === '待发货'
                        ? '#FFB300'
                        : _vm.getStatus(item.status) === '已退款'
                        ? '#1890ff'
                        : '#FFB300',
                  })}),_c('span',[_vm._v(_vm._s(_vm.getStatus(item.status)))]),(
                    _vm.getStatus(item.status) === '已完成' && item.spec.refund
                  )?_c('div',{staticStyle:{"color":"#1890ff","cursor":"pointer"},on:{"click":function($event){return _vm.to_afterSale(item._id)}}},[_vm._v(" (此单含退款) ")]):_vm._e()])]),_c('td',{class:_vm.getStatus(item.status) === '已取消' ||
                _vm.getStatus(item.status) === '已完成' ||
                _vm.getStatus(item.status) === '已退款'
                  ? 'gray_td'
                  : 'blue_td'},[_c('div',{staticClass:"tr_info_td td_operate"},[_c('div',{class:{
                    hidden: !item.goodsMore && item.spec.items.length > 3,
                  },staticStyle:{"width":"100%"}},_vm._l((item.spec.items),function(goodsItem,goodsIndex){return _c('div',{key:goodsIndex,staticClass:"goods_list",staticStyle:{"height":"60px"},style:({
                      marginBottom:
                        item.spec.items.length === 1 ? '0px' : '15px',
                    })},[_c('div',{staticClass:"goods_num",staticStyle:{"text-align":"center"}},[_vm._v(" ￥"+_vm._s(goodsItem.snapshot.commissionRate ? ( (goodsItem.count * goodsItem.sku.price * goodsItem.snapshot.commissionRate) / 100 ).toFixed(2) : 0)+" ")])])}),0)])]),_c('td',{staticClass:"last operate_width",class:_vm.getStatus(item.status) === '已取消' ||
                _vm.getStatus(item.status) === '已完成' ||
                _vm.getStatus(item.status) === '已退款'
                  ? 'gray_td'
                  : 'blue_td'},[_c('div',{staticStyle:{"text-align":"center"}},[_vm._v(" "+_vm._s(_vm.getCommission(item.spec.items) ? (_vm.getCommission(item.spec.items) / 100).toFixed(2) : 0)+" ")])])]):_vm._e()])})],2),_c('pagination',{directives:[{name:"show",rawName:"v-show",value:(_vm.total > 0),expression:"total > 0"}],attrs:{"total":_vm.total,"page":_vm.listParams.page,"limit":_vm.listParams.limit},on:{"update:page":function($event){return _vm.$set(_vm.listParams, "page", $event)},"update:limit":function($event){return _vm.$set(_vm.listParams, "limit", $event)},"pagination":_vm.updateList}})],1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('thead',[_c('tr',[_c('th',{staticClass:"goods_width"},[_vm._v("商品")]),_c('th',[_vm._v("购买数量")]),_c('th',[_vm._v("实付款")]),_c('th',[_vm._v("买家/收货人信息")]),_c('th',[_vm._v("订单状态")]),_c('th',[_vm._v("分佣信息")]),_c('th',[_vm._v("分佣总计")])])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"none_data"},[_c('td',{attrs:{"colspan":"8"}},[_vm._v("暂无数据")])])
},function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('tr',{staticClass:"tr_placeholder"},[_c('td',{attrs:{"colspan":"8"}})])
}]

export { render, staticRenderFns }

import {Component, Vue, Watch} from 'vue-property-decorator';
import moment from 'moment';
import {shopLabels, shops, couponActivitys} from '../../../../resources';
import {ShopLabel} from '@/externals/MaxCI-ShopLabel-v1';
import {ObjectId} from 'bson';
import {Shop} from '@/externals/MaxCI-Shop-v1';
import {Form} from 'element-ui';
import {
  ExpirationTakeNext,
  ExpirationFixed,
  ExpirationTake,
  TimeRange,
  CouponReduction,
  ReceivableApplicationCouponActivitySpec,
  DistributeApplicationCouponActivitySpec,
} from '@/externals/MaxCI-CouponActivity-v1';
import {AxiosError} from 'axios';

interface ShopValue extends ShopLabel {
  values: Array<{
    name: string;
    checked: boolean;
  }>;
}
@Component({
  name: 'couponEdit',
})
export default class extends Vue {
  private validprice = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(value)) {
      callback(new Error('价格格式有误'));
    } else {
      callback();
    }
  };
  private validnum = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/^[1-9]\d*|0$/.test(value)) {
      callback(new Error('数量必须为正整数'));
    } else {
      callback();
    }
  };
  private submitFlag = false;
  private selectedShopShow = false; //查看预选中店铺
  private allShopCheck = false; //店铺是否全选
  private total = 0;
  private shopList: Array<Shop> = [];
  private listLoading = true;
  private selectShopLabels: Array<string> = []; //选择的店铺标签
  private labelData: Array<ShopValue> = []; //搜索标签数据
  private dialogSelectShop = false; //选择店铺标签
  private formData = {
    startDate: null as null | Date,
    endDate: null as null | Date,
    shopIdsType: '全部',
    shopIds: [] as Array<string>, //店铺id
    receiveTypes: [] as Array<'应用' | '店铺' | '自取'>,
    orderTypes: [] as Array<'商城' | '二手' | '代办'>,
    type: '派发', //优惠券活动类型
    couponType: '减免',
    useThreshold: '0', //使用门槛 无门槛，订单消费满
    orderAmount: 0, //订单满金额
    exemptionPrice: null as null | number, //减免金额
    name: '', //优惠券活动名称
    activityTime: [] as Array<Date>, //活动时间
    availableFlag: '0', //全部时段 部分时段
    availableTime: [
      moment().startOf('day').toDate(),
      moment().startOf('day').add({day: 1}).subtract({minute: 10}).toDate(),
    ], //可用时段
    validPeriod: '0', //有效期
    fixedDate: [] as Array<Date>, //固定日期
    thatDay: null as null | number, //当天有效
    nextDay: null as null | number, //次日有效
    dailyQuantity: null as null | number, //每日发放数量
    userDailyQuantity: null as null | number, //每人每日限领
    userTotalQuantity: null as null | number, //每人总计可领
    firstOrder: null as null | boolean, //领取人限制
    description: '',
  };
  private rules = {
    shopIdsType: [
      {required: true, message: '请选择优惠券类型', trigger: 'blur'},
    ],
    orderTypes: [
      {required: true, message: '请选择订单类型限制', trigger: 'blur'},
    ],
    receiveTypes: [
      {required: true, message: '请选择订单配送名称', trigger: 'blur'},
    ],
    type: [{required: true, message: '请选择活动类型', trigger: 'blur'}],
    useThreshold: [
      {required: true, message: '请选择使用门槛', trigger: 'blur'},
    ],
    name: [{required: true, message: '请输入优惠券活动名称', trigger: 'blur'}],
    exemptionPrice: [
      {required: true, message: '请输入减免金额', trigger: 'blur'},
      {validator: this.validprice, trigger: 'blur'},
    ],
    activityTime: [
      {required: true, message: '请选择活动时间', trigger: 'blur'},
    ],
    dailyQuantity: [
      {required: true, message: '请输入每日发放数量', trigger: 'blur'},
      {validator: this.validnum, trigger: 'blur'},
    ],
    userDailyQuantity: [
      {required: true, message: '请输入每人每日限领', trigger: 'blur'},
      {validator: this.validnum, trigger: 'blur'},
    ],
    userTotalQuantity: [
      {required: true, message: '请输入每人总计可领：', trigger: 'blur'},
      {validator: this.validnum, trigger: 'blur'},
    ],
    firstOrder: [
      {required: true, message: '请选择领取人限制', trigger: 'blur'},
    ],
    validPeriod: [{required: true, message: '请选择有效期', trigger: 'blur'}],
  };
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private couponStatus = ''; //优惠券状态
  async created() {
    await this.checkShopLabel();
    await this.checkShop();
    const detailMsg = (
      await couponActivitys.find(stage =>
        stage.$match(match =>
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          )(
            f => f('_id'),
            e => e.$eq(this.detailId),
          ),
        ),
      )
    ).find(() => true);
    if (detailMsg) {
      this.formData.type = detailMsg.spec.type;
      if ('orderTypes' in detailMsg.spec) {
        this.formData.orderTypes = detailMsg.spec.orderTypes;
      }
      this.formData.receiveTypes = detailMsg.spec.receiveTypes;
      //店铺id
      if ('shopIds' in detailMsg.spec) {
        if (detailMsg.spec.shopIds.length === 0) {
          this.formData.shopIdsType = '全部';
          this.formData.shopIds = [];
        } else {
          this.formData.shopIdsType = '部分';
          this.formData.shopIds = detailMsg.spec.shopIds.map(v =>
            v.toHexString(),
          );
        }
      }
      this.formData.couponType = detailMsg.spec.discount.type;
      this.formData.description = detailMsg.spec.description ?? '';
      if ('applicationDiscount' in detailMsg.spec.discount) {
        this.formData.exemptionPrice = Number(
          (detailMsg.spec.discount.applicationDiscount / 100).toFixed(2),
        );
      }
      //使用门槛
      if (detailMsg.spec.orderAmount === 0) {
        this.formData.useThreshold = '0';
      } else {
        this.formData.useThreshold = '1';
        this.formData.orderAmount = detailMsg.spec.orderAmount / 100;
      }
      this.formData.name = detailMsg.spec.name;
      //活动时间
      if (detailMsg.spec.type === '领取') {
        this.formData.activityTime = [
          detailMsg.spec.receivableDate.begin ?? new Date(),
          detailMsg.spec.receivableDate.end ?? new Date(),
        ];
        this.formData.startDate =
          detailMsg.spec.receivableDate.begin ?? new Date();
        this.formData.endDate = detailMsg.spec.receivableDate.end ?? new Date();
        this.formData.dailyQuantity = detailMsg.spec.quantity.daily;
        this.formData.userDailyQuantity = detailMsg.spec.quantity.userDaily;
        this.formData.userTotalQuantity = detailMsg.spec.quantity.userTotal;
        //判断优惠券状态
        if (moment().isBefore(moment(detailMsg.spec.receivableDate.end))) {
          if (moment().isBefore(moment(detailMsg.spec.receivableDate.begin))) {
            this.couponStatus = '未开始';
          } else {
            this.couponStatus = '进行中';
          }
        } else {
          this.couponStatus = '已结束';
        }
      }
      // 时间范围
      if (detailMsg.spec.times.length === 0) {
        this.formData.availableFlag = '0';
      } else {
        this.formData.availableFlag = '1';
        this.formData.availableTime = [
          moment().startOf('days').add(detailMsg.spec.times[0].begin).toDate(),
          moment().startOf('days').add(detailMsg.spec.times[0].end).toDate(),
        ];
      }
      //有效期
      if (detailMsg.spec.expiration.type === '固定日期') {
        this.formData.validPeriod = '0';
        this.formData.fixedDate = [
          detailMsg.spec.expiration.begin ?? new Date(),
          detailMsg.spec.expiration.end ?? new Date(),
        ];
      } else if (detailMsg.spec.expiration.type === '领取当日') {
        this.formData.validPeriod = '1';
        this.formData.thatDay = detailMsg.spec.expiration.day;
      } else if (detailMsg.spec.expiration.type === '领取次日') {
        this.formData.validPeriod = '2';
        this.formData.nextDay = detailMsg.spec.expiration.day;
      }
      this.formData.firstOrder = detailMsg.spec.firstOrder;
    }
  }
  // //选择使用门槛
  @Watch('formData.useThreshold')
  async onUseThresholdChange() {
    // this.formData.orderAmount = 0;
  }
  //保存
  private async submitForm() {
    (this.$refs.formData as Form).validate(async valid => {
      if (valid) {
        try {
          //订单金额
          if (
            this.formData.useThreshold === '1' &&
            !/(^[1-9]\d*(\.\d{1,2})?$)|(^0(\.\d{1,2})?$)/.test(
              this.formData.orderAmount
                ? this.formData.orderAmount.toString()
                : '',
            )
          ) {
            this.$message.error('订单消费满金额格式不正确');
            return false;
          }
          //店铺
          let shopIds = [] as Array<ObjectId>;
          if (this.formData.shopIdsType === '全部') {
            shopIds = [];
          } else {
            if (this.formData.shopIds.length === 0) {
              this.$message.error('请选择店铺');
              return false;
            } else {
              shopIds = this.formData.shopIds.map(v =>
                ObjectId.createFromHexString(v),
              );
            }
          }
          //订单类型限制
          if (this.formData.orderTypes.length === 0) {
            this.$message.error('请选择订单类型');
            return false;
          }
          //订单收货类型
          if (this.formData.receiveTypes.length === 0) {
            this.$message.error('请选择订单收货类型');
            return false;
          }
          //有效期
          if (
            this.formData.validPeriod === '0' &&
            this.formData.fixedDate.length === 0
          ) {
            this.$message.error('请选择固定日期');
            return false;
          }
          if (
            this.formData.validPeriod === '1' &&
            !/^([1-9][0-9]*)$/.test(
              this.formData.thatDay ? this.formData.thatDay.toString() : '',
            )
          ) {
            this.$message.error('有效期天数格式不正确');
            return false;
          }
          if (
            this.formData.validPeriod === '2' &&
            !/^([1-9][0-9]*)$/.test(
              this.formData.nextDay ? this.formData.nextDay.toString() : '',
            )
          ) {
            this.$message.error('有效期天数格式不正确');
            return false;
          }
          //有效期
          let expiration:
            | ExpirationFixed
            | ExpirationTakeNext
            | ExpirationTake
            | null = null;
          if (this.formData.validPeriod === '0') {
            expiration = {
              type: '固定日期',
              begin: moment(this.formData.fixedDate[0]).toDate(),
              end: moment(this.formData.fixedDate[1]).endOf('days').toDate(),
            };
          } else if (this.formData.validPeriod === '1') {
            expiration = {
              type: '领取当日',
              day: Number(this.formData.thatDay),
            };
          } else if (this.formData.validPeriod === '2') {
            expiration = {
              type: '领取次日',
              day: Number(this.formData.nextDay),
            };
          }
          //使用时间段
          let times: Array<TimeRange> = [];
          if (this.formData.availableFlag === '0') {
            times = [];
          } else if (this.formData.availableFlag === '1') {
            times.push({
              begin: moment(this.formData.availableTime[0]).format('HH:mm'),
              end: moment(this.formData.availableTime[1]).format('HH:mm'),
            });
          }
          //优惠方式
          let discount: CouponReduction | null = null;
          if (this.formData.couponType === '减免') {
            discount = {
              type: '减免',
              applicationDiscount: Math.round(
                Number(this.formData.exemptionPrice) * 100,
              ),
              shopDiscount: 0,
            };
          }
          if (discount && expiration && this.formData.firstOrder !== null) {
            this.submitFlag = true;
            const publicData = {
              shopIds: shopIds,
              discount: discount,
              name: this.formData.name,
              expiration: expiration,
              orderTypes: this.formData.orderTypes as Array<
                '商城' | '二手' | '代办'
              >,
              receiveTypes: this.formData.receiveTypes,
              ...(this.formData.useThreshold === '0'
                ? {orderAmount: 0}
                : {
                    orderAmount: Math.round(
                      Number(this.formData.orderAmount) * 100,
                    ),
                  }),
              firstOrder: this.formData.firstOrder,
              times: times,
              applicationId: this.applicationId,
              description: this.formData.description,
            };
            let data = null as
              | null
              | ReceivableApplicationCouponActivitySpec
              | DistributeApplicationCouponActivitySpec;
            if (this.formData.type === '领取') {
              //活动时间
              data = {
                type: '领取',
                quantity: {
                  daily: Number(this.formData.dailyQuantity),
                  userDaily: Number(this.formData.userDailyQuantity),
                  userTotal: Number(this.formData.userTotalQuantity),
                },
                receivableDate: {
                  begin:
                    this.couponStatus === '进行中'
                      ? moment(this.formData.startDate).toDate()
                      : moment(this.formData.activityTime[0]).toDate(),
                  end:
                    this.couponStatus === '进行中'
                      ? moment(this.formData.endDate).endOf('days').toDate()
                      : moment(this.formData.activityTime[1])
                          .endOf('days')
                          .toDate(),
                },
                ...publicData,
              };
            } else {
              data = {
                type: '派发',
                ...publicData,
              };
            }
            if (data) {
              const couponActivity = await couponActivitys.update(
                filter =>
                  filter(
                    f => f('_id'),
                    e => e.$eq(this.detailId),
                  )(
                    f => f('spec')('applicationId'),
                    e => e.$eq(this.applicationId),
                  ),
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                update => update.$set(s => s(f => f('spec'), data!)),
              );
              if (couponActivity.length > 0) {
                this.$message.success('修改成功');
                this.backBtn();
              }
            }
          }
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (e: any) {
          if (e.isAxiosError) {
            const axiosError = e as AxiosError;
            if (
              axiosError.response?.status === 500 &&
              (axiosError.response.data.name === 'MongoError' ||
                axiosError.response.data.name === 'Error') &&
              axiosError.response.data.message.indexOf('E11000') > -1
            ) {
              this.$message.error('名称不能重复');
            }
          }
        } finally {
          this.submitFlag = false;
        }
      } else {
        return false;
      }
    });
  }
  //选择全部、部分店铺
  private changeShopType() {
    if (this.formData.shopIdsType === '全部') {
      this.formData.shopIds = [];
      this.selectShopLabels = [];
      this.allShopCheck = false;
      this.labelData.forEach(item => {
        item.values.forEach(valItem => {
          valItem.checked = false;
        });
      });
    }
  }
  //查看已选中店铺
  private async checkSelectedShop() {
    this.selectShopLabels = [];
    this.labelData.forEach(item => {
      item.values.forEach(valItem => {
        valItem.checked = false;
      });
    });
    if (this.selectedShopShow) {
      const shopIds = this.formData.shopIds.map(v =>
        ObjectId.createFromHexString(v),
      );
      try {
        const list = await shops.find(stage =>
          stage
            .$match(match => {
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              )(
                f => f('_id'),
                e => e.$in(shopIds),
              );
              return match;
            })
            .$facet(facet => facet('table', tableStage => tableStage)),
        );
        this.shopList = list[0].table;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } catch (e: any) {
        console.log(e);
        this.$message.error('网络异常，请稍后重试');
      }
    } else {
      this.checkShop();
    }
  }
  //全选
  private selectAllShop() {
    if (this.allShopCheck) {
      this.shopList.forEach(item => {
        if (this.formData.shopIds.indexOf(item._id.toHexString()) === -1) {
          this.formData.shopIds.push(item._id.toHexString());
        }
      });
    } else {
      const shopList = this.shopList.map(v => v._id.toHexString());
      const shopIds = shopList.filter(items => {
        if (!this.formData.shopIds.includes(items)) return items;
      });
      this.formData.shopIds = shopIds;
    }
  }
  //查询店铺标签
  private async checkShopLabel() {
    //查询标签数据
    const list = (await shopLabels.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        ),
      ),
    )) as Array<ShopValue>;
    list.forEach(item => {
      item.values = [];
      if (item.values) {
        item.spec.values.forEach(valueItem => {
          item.values.push({
            name: valueItem,
            checked: false,
          });
        });
      }
    });
    this.labelData = list;
  }
  //处理时间段
  get periodTime() {
    let time = null;
    if (this.formData.availableFlag === '0') {
      time = '00:00 - 23:59';
    } else if (this.formData.availableFlag === '1') {
      time =
        moment(this.formData.availableTime[0]).format('HH:mm') +
        '-' +
        moment(this.formData.availableTime[1]).format('HH:mm');
    }
    return time;
  }
  //处理有效期
  get effectiveTime() {
    let time = null;
    if (this.formData.validPeriod === '0') {
      if (this.formData.fixedDate.length !== 0) {
        time =
          moment(this.formData.fixedDate[0]).format('YYYY-MM-DD 00:00') +
          '-' +
          moment(this.formData.fixedDate[1]).format('YYYY-MM-DD 23:59');
      }
    } else if (this.formData.validPeriod === '1') {
      time =
        moment().startOf('day').format('YYYY-MM-DD 00:00') +
        '-' +
        moment()
          .add({day: Number(this.formData.thatDay) - 1})
          .format('YYYY-MM-DD 23:59');
    } else if (this.formData.validPeriod === '2') {
      time =
        moment().add({day: 1}).format('YYYY-MM-DD 00:00') +
        '-' +
        moment()
          .add({day: Number(this.formData.nextDay)})
          .format('YYYY-MM-DD 23:59');
    }
    return time;
  }
  //查询店铺
  private async checkShop() {
    try {
      const list = await shops.find(stage =>
        stage
          .$match(match => {
            if (this.selectShopLabels.length > 0) {
              match(
                f => f('spec')('labels'),
                e => e.$in(this.selectShopLabels),
              );
            }
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            return match;
          })
          .$facet(facet => facet('table', tableStage => tableStage)),
      );
      this.shopList = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    }
  }
  private endDatePickerOptions = {
    disabledDate: (time: Date) => {
      const startDate = this.formData.startDate as Date;
      return time.getTime() < startDate.getTime();
    },
  };
  //选择店铺标签
  private async selectLabel() {
    const labels = [] as Array<string>;
    this.labelData.forEach(item => {
      item.values.forEach(valItem => {
        if (valItem.checked) {
          labels.push(item.spec.name + ':' + valItem.name);
        }
      });
    });
    this.selectShopLabels = labels;
    await this.checkShop();
    //判断全选按钮是否选中
    const shopList = [] as Array<string>;
    this.shopList.forEach(item => {
      this.formData.shopIds.forEach(shopItem => {
        if (item._id.equals(ObjectId.createFromHexString(shopItem))) {
          shopList.push(shopItem);
        }
      });
    });
    if (shopList.length === this.shopList.length) {
      this.allShopCheck = true;
    } else {
      this.allShopCheck = false;
    }
  }
  //选择店铺弹窗
  private async selectPopUps() {
    await this.checkShopLabel();
    await this.checkShop();
    this.dialogSelectShop = true;
  }
  //选择可用时间
  private async changeAvailableTime() {
    this.formData.availableTime = [
      moment().startOf('day').toDate(),
      moment().startOf('day').add({day: 1}).subtract({minute: 10}).toDate(),
    ];
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'list',
      },
    });
  }
}


import {Component, Vue} from 'vue-property-decorator';
import Submenu from '@/components/Submenu/index.vue';
import coupon from './components/coupon/index.vue';
import distribution from './components/distribution/index.vue';
@Component({
  name: 'activity',
  components: {
    Submenu,
    coupon,
    distribution,
  },
})
export default class extends Vue {
  private menu = '';
  private submenu = [
    {
      top: '',
      list: [
        {name: '优惠券活动', active: true},
        {name: '分销员', active: false},
      ],
    },
  ];
  created() {
    this.$store.state.applicationId = this.$route.params.applicationId;
    this.menu = this.$route.query.menu
      ? (this.$route.query.menu as string)
      : '优惠券活动';
  }
}

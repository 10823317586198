
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {
  roles,
  roleMappings,
  users,
  orders,
  userViews,
} from '../../../../resources';
import {ObjectId} from 'bson';
import lodash from 'lodash';
import {RoleMapping} from '@/externals/MaxCI-RoleMapping-v1';
import {AnyObject} from '@warehouse/core';
import {User} from '@/externals';
import xlsx from 'xlsx';
import moment from 'moment';

@Component({
  name: 'distributionList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private loading = false;
  private searchData = {
    name: '',
    phone: '',
  };
  private total = 0;
  private list: Array<RoleMapping> = [];
  private listLoading = false;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private roleId: null | ObjectId = null;
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private userInfoData = {} as any; //用户信息
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private userViewData = {} as any; //累计邀请人数
  private orderData: Array<AnyObject> = []; //统计
  async created() {
    //查询应用用户_邀请员角色id
    this.roleId =
      (
        await roles.find(stage =>
          stage.$match(match =>
            match(
              f => f('spec')('name'),
              e => e.$eq('应用用户_邀请员'),
            ),
          ),
        )
      ).find(() => true)?._id ?? null;
    this.updateList();
  }
  //获取用户信息
  private getUserInfo(type: string, id: ObjectId) {
    let info = '';
    if (this.userInfoData && this.userInfoData[id.toHexString()]) {
      if (type === 'name') {
        info = this.userInfoData[id.toHexString()].spec.name;
      } else if (type === 'phone') {
        info = this.userInfoData[id.toHexString()].spec.phone;
      } else if (type === 'avatar') {
        info = this.userInfoData[id.toHexString()].spec.avatar ?? '';
      }
    }
    return info;
  }
  //获取统计数据
  private getNum(orderData: Array<AnyObject>, type: string, id: ObjectId) {
    let num = 0;
    if (orderData && orderData.length > 0) {
      const arr = orderData[0];
      for (let i = 0; i < arr[type].length; i++) {
        if (arr[type][i]._id.equals(id)) {
          for (let j in arr[type][i]) {
            if (j !== '_id') {
              num = arr[type][i][j];
            }
          }
        }
      }
    }
    return num;
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //新增
  private addBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'couponAdd'},
    });
  }
  //查看数据详情
  private to_detail(id: ObjectId) {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'detail',
        id: id.toHexString(),
      },
    });
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //获取列表
  private async updateList() {
    this.listLoading = true;
    try {
      let user = [] as Array<User>;
      if (this.searchData.name || this.searchData.phone) {
        user = await users.find(stage =>
          stage.$match(match => {
            if (this.searchData.name) {
              match(
                f => f('spec')('name'),
                e => e.$eq(this.searchData.name),
              );
            }
            if (this.searchData.phone) {
              match(
                f => f('spec')('phone'),
                e => e.$eq(this.searchData.phone),
              );
            }
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            return match;
          }),
        );
      }
      const list = await roleMappings.find(stage =>
        stage
          .$match(match => {
            if (this.searchData.name || this.searchData.phone) {
              match(
                f => f('spec')('userId'),
                e => e.$in(user.map(v => v._id)),
              );
            }
            match(
              f => f('spec')('roleId'),
              e => e.$eq(this.roleId as ObjectId),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );

      //用户信息
      let userIdData: Array<ObjectId> = [];
      list[0].table.forEach(item => {
        userIdData.push(item.spec.userId);
      });
      userIdData = Array.from(new Set(userIdData));
      const userData = await users.find(stage =>
        stage.$match(match =>
          match(
            f => f('_id'),
            e => e.$in(userIdData),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        ),
      );
      this.userInfoData = lodash.zipObject(
        userData.map(v => v._id.toHexString()),
        userData,
      );

      //累计邀请客户人数
      const userViewData = await userViews.find(stage =>
        stage
          .$match(match =>
            match(
              f => f('spec')('inviterId'),
              e => e.$in(userIdData),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            ),
          )
          .$group(
            e => e.$fieldPath(f => f('spec')('inviterId')),
            group => group('count', e => e.$sum(sum => sum.$literal(1))),
          ),
      );
      this.userViewData = lodash.zipObject(
        userViewData.map(v => (v._id as ObjectId).toHexString()),
        userViewData,
      );

      //统计
      this.orderData = await orders.find(stage =>
        stage
          .$match(match =>
            match(
              f => f('spec')('inviterId'),
              e => e.$in(userIdData),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            )(
              f => f('spec')('type'),
              e => e.$eq('商城'),
            ),
          )
          .$facet(facet =>
            facet('allProfit', chartsStage =>
              chartsStage
                .$match(match =>
                  match(
                    f => f('status')('phase'),
                    e => e.$eq('完成'),
                  )(
                    f => f('spec')('refund'),
                    e => e.$exists(false),
                  ),
                )
                .$replaceWith(replaceWith =>
                  replaceWith.$object(object =>
                    object('items', e => e.$fieldPath(f => f('spec')('items')))(
                      'spec',
                      e => e.$fieldPath(f => f('spec')),
                    )('_id', e => e.$fieldPath(f => f('_id')))('status', e =>
                      e.$fieldPath(f => f('status')),
                    ),
                  ),
                )
                .$unwind(f => f('items'), undefined, false)
                .$group(
                  e => e.$fieldPath(f => f('spec')('inviterId')),
                  group =>
                    group('profit', e =>
                      e.$sum(sum =>
                        sum.$multiply(
                          multiply =>
                            multiply.$fieldPath(f =>
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              //  @ts-ignore
                              f('items')('snapshot')('commissionRate'),
                            ),
                          multiply =>
                            multiply.$fieldPath(f => f('items')('count')),
                          multiply =>
                            multiply.$fieldPath(f =>
                              f('items')('sku')('price'),
                            ),
                        ),
                      ),
                    ),
                ),
            )('estimateProfit', chartsStage =>
              chartsStage
                .$match(match =>
                  match(
                    f => f('status')('phase'),
                    e => e.$ne('取消'),
                  )(
                    f => f('status')('payInfo'),
                    e => e.$exists(true),
                  )(
                    f => f('spec')('refund'),
                    e => e.$exists(false),
                  ),
                )
                .$replaceWith(replaceWith =>
                  replaceWith.$object(object =>
                    object('items', e => e.$fieldPath(f => f('spec')('items')))(
                      'spec',
                      e => e.$fieldPath(f => f('spec')),
                    )('_id', e => e.$fieldPath(f => f('_id')))('status', e =>
                      e.$fieldPath(f => f('status')),
                    ),
                  ),
                )
                .$unwind(f => f('items'), undefined, false)
                .$group(
                  e => e.$fieldPath(f => f('spec')('inviterId')),
                  group =>
                    group('profit', e =>
                      e.$sum(sum =>
                        sum.$multiply(
                          multiply =>
                            multiply.$fieldPath(f =>
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              //  @ts-ignore
                              f('items')('snapshot')('commissionRate'),
                            ),
                          multiply =>
                            multiply.$fieldPath(f => f('items')('count')),
                          multiply =>
                            multiply.$fieldPath(f =>
                              f('items')('sku')('price'),
                            ),
                        ),
                      ),
                    ),
                ),
            )('allPayment', chartsStage =>
              chartsStage
                .$match(match =>
                  match(
                    f => f('status')('phase'),
                    e => e.$ne('取消'),
                  )(
                    f => f('status')('payInfo'),
                    e => e.$exists(true),
                  )(
                    f => f('spec')('refund'),
                    e => e.$exists(false),
                  ),
                )
                .$group(
                  e => e.$fieldPath(f => f('spec')('inviterId')),
                  group =>
                    group('payment', e =>
                      e.$sum(sum =>
                        sum.$fieldPath(f => f('spec')('amount')('user')),
                      ),
                    ),
                ),
            )('orderPaymentNum', chartsStage =>
              chartsStage
                .$match(match =>
                  match(
                    f => f('status')('phase'),
                    e => e.$ne('取消'),
                  )(
                    f => f('status')('payInfo'),
                    e => e.$exists(true),
                  )(
                    f => f('spec')('refund'),
                    e => e.$exists(false),
                  ),
                )
                .$group(
                  e => e.$fieldPath(f => f('spec')('inviterId')),
                  group =>
                    group('paymentNum', e => e.$sum(sum => sum.$literal(1))),
                ),
            )('allPrice', chartsStage =>
              chartsStage
                .$match(match =>
                  match(
                    f => f('status')('phase'),
                    e => e.$eq('完成'),
                  )(
                    f => f('spec')('refund'),
                    e => e.$exists(false),
                  ),
                )
                .$group(
                  e => e.$fieldPath(f => f('spec')('inviterId')),
                  group =>
                    group('allNum', e =>
                      e.$sum(sum =>
                        sum.$fieldPath(f => f('spec')('amount')('user')),
                      ),
                    ),
                ),
            )('allOrderNum', chartsStage =>
              chartsStage
                .$match(match =>
                  match(
                    f => f('status')('phase'),
                    e => e.$eq('完成'),
                  )(
                    f => f('spec')('refund'),
                    e => e.$exists(false),
                  ),
                )
                .$group(
                  e => e.$fieldPath(f => f('spec')('inviterId')),
                  group => group('count', e => e.$sum(sum => sum.$literal(1))),
                ),
            ),
          ),
      );
      console.log(this.orderData);
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  // 导出报表
  private async exportReport() {
    this.loading = true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const reportList: any = [];
    try {
      let user = [] as Array<User>;
      if (this.searchData.name || this.searchData.phone) {
        user = await users.find(stage =>
          stage.$match(match => {
            if (this.searchData.name) {
              match(
                f => f('spec')('name'),
                e => e.$eq(this.searchData.name),
              );
            }
            if (this.searchData.phone) {
              match(
                f => f('spec')('phone'),
                e => e.$eq(this.searchData.phone),
              );
            }
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            return match;
          }),
        );
      }
      const count = (
        await roleMappings.find(stage =>
          stage
            .$match(match => {
              if (this.searchData.name || this.searchData.phone) {
                match(
                  f => f('spec')('userId'),
                  e => e.$in(user.map(v => v._id)),
                );
              }
              match(
                f => f('spec')('roleId'),
                e => e.$eq(this.roleId as ObjectId),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              );
              return match;
            })
            .$facet(facet =>
              facet('count', countStage => countStage.$count('count')),
            ),
        )
      )[0].count[0].count.valueOf();
      if (count) {
        reportList.push([
          '用户账号',
          '昵称',
          '累计邀请客户(人)',
          '支付金额(￥)',
          '付款订单数',
          '成交金额(￥)',
          '成交订单数',
          '预估佣金收益(￥)',
          '佣金收益(￥)',
        ]);
        let i = 0;
        while (i < Math.ceil(count / 100)) {
          i++;
          const list = await roleMappings.find(stage =>
            stage
              .$match(match => {
                if (this.searchData.name || this.searchData.phone) {
                  match(
                    f => f('spec')('userId'),
                    e => e.$in(user.map(v => v._id)),
                  );
                }
                match(
                  f => f('spec')('roleId'),
                  e => e.$eq(this.roleId as ObjectId),
                )(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                );
                return match;
              })
              .$facet(facet =>
                facet('table', tableStage =>
                  tableStage
                    .$sort(sort =>
                      sort(f => f('metadata')('creationTimestamp'), '降序'),
                    )
                    .$skip((i - 1) * 100)
                    .$limit(100),
                ),
              ),
          );

          //用户信息
          let userIdData: Array<ObjectId> = [];
          list[0].table.forEach(item => {
            userIdData.push(item.spec.userId);
          });
          userIdData = Array.from(new Set(userIdData));
          const userData = await users.find(stage =>
            stage.$match(match =>
              match(
                f => f('_id'),
                e => e.$in(userIdData),
              )(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              ),
            ),
          );
          const userInfoData = lodash.zipObject(
            userData.map(v => v._id.toHexString()),
            userData,
          );

          //累计邀请客户人数
          const userView = await userViews.find(stage =>
            stage
              .$match(match =>
                match(
                  f => f('spec')('inviterId'),
                  e => e.$in(userIdData),
                )(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                ),
              )
              .$group(
                e => e.$fieldPath(f => f('spec')('inviterId')),
                group => group('count', e => e.$sum(sum => sum.$literal(1))),
              ),
          );
          const userViewData = lodash.zipObject(
            userView.map(v => (v._id as ObjectId).toHexString()),
            userView,
          );

          //统计
          const orderData = await orders.find(stage =>
            stage
              .$match(match =>
                match(
                  f => f('spec')('inviterId'),
                  e => e.$in(userIdData),
                )(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('spec')('type'),
                  e => e.$eq('商城'),
                ),
              )
              .$facet(facet =>
                facet('allProfit', chartsStage =>
                  chartsStage
                    .$match(match =>
                      match(
                        f => f('status')('phase'),
                        e => e.$eq('完成'),
                      )(
                        f => f('spec')('refund'),
                        e => e.$exists(false),
                      ),
                    )
                    .$replaceWith(replaceWith =>
                      replaceWith.$object(object =>
                        object('items', e =>
                          e.$fieldPath(f => f('spec')('items')),
                        )('spec', e => e.$fieldPath(f => f('spec')))('_id', e =>
                          e.$fieldPath(f => f('_id')),
                        )('status', e => e.$fieldPath(f => f('status'))),
                      ),
                    )
                    .$unwind(f => f('items'), undefined, false)
                    .$group(
                      e => e.$fieldPath(f => f('spec')('inviterId')),
                      group =>
                        group('profit', e =>
                          e.$sum(sum =>
                            sum.$multiply(
                              multiply =>
                                multiply.$fieldPath(f =>
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  //  @ts-ignore
                                  f('items')('snapshot')('commissionRate'),
                                ),
                              multiply =>
                                multiply.$fieldPath(f => f('items')('count')),
                              multiply =>
                                multiply.$fieldPath(f =>
                                  f('items')('sku')('price'),
                                ),
                            ),
                          ),
                        ),
                    ),
                )('estimateProfit', chartsStage =>
                  chartsStage
                    .$match(match =>
                      match(
                        f => f('status')('phase'),
                        e => e.$ne('取消'),
                      )(
                        f => f('status')('payInfo'),
                        e => e.$exists(true),
                      )(
                        f => f('spec')('refund'),
                        e => e.$exists(false),
                      ),
                    )
                    .$replaceWith(replaceWith =>
                      replaceWith.$object(object =>
                        object('items', e =>
                          e.$fieldPath(f => f('spec')('items')),
                        )('spec', e => e.$fieldPath(f => f('spec')))('_id', e =>
                          e.$fieldPath(f => f('_id')),
                        )('status', e => e.$fieldPath(f => f('status'))),
                      ),
                    )
                    .$unwind(f => f('items'), undefined, false)
                    .$group(
                      e => e.$fieldPath(f => f('spec')('inviterId')),
                      group =>
                        group('profit', e =>
                          e.$sum(sum =>
                            sum.$multiply(
                              multiply =>
                                multiply.$fieldPath(f =>
                                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  //  @ts-ignore
                                  f('items')('snapshot')('commissionRate'),
                                ),
                              multiply =>
                                multiply.$fieldPath(f => f('items')('count')),
                              multiply =>
                                multiply.$fieldPath(f =>
                                  f('items')('sku')('price'),
                                ),
                            ),
                          ),
                        ),
                    ),
                )('allPayment', chartsStage =>
                  chartsStage
                    .$match(match =>
                      match(
                        f => f('status')('phase'),
                        e => e.$ne('取消'),
                      )(
                        f => f('status')('payInfo'),
                        e => e.$exists(true),
                      )(
                        f => f('spec')('refund'),
                        e => e.$exists(false),
                      ),
                    )
                    .$group(
                      e => e.$fieldPath(f => f('spec')('inviterId')),
                      group =>
                        group('payment', e =>
                          e.$sum(sum =>
                            sum.$fieldPath(f => f('spec')('amount')('user')),
                          ),
                        ),
                    ),
                )('orderPaymentNum', chartsStage =>
                  chartsStage
                    .$match(match =>
                      match(
                        f => f('status')('phase'),
                        e => e.$ne('取消'),
                      )(
                        f => f('status')('payInfo'),
                        e => e.$exists(true),
                      )(
                        f => f('spec')('refund'),
                        e => e.$exists(false),
                      ),
                    )
                    .$group(
                      e => e.$fieldPath(f => f('spec')('inviterId')),
                      group =>
                        group('paymentNum', e =>
                          e.$sum(sum => sum.$literal(1)),
                        ),
                    ),
                )('allPrice', chartsStage =>
                  chartsStage
                    .$match(match =>
                      match(
                        f => f('status')('phase'),
                        e => e.$eq('完成'),
                      )(
                        f => f('spec')('refund'),
                        e => e.$exists(false),
                      ),
                    )
                    .$group(
                      e => e.$fieldPath(f => f('spec')('inviterId')),
                      group =>
                        group('allNum', e =>
                          e.$sum(sum =>
                            sum.$fieldPath(f => f('spec')('amount')('user')),
                          ),
                        ),
                    ),
                )('allOrderNum', chartsStage =>
                  chartsStage
                    .$match(match =>
                      match(
                        f => f('status')('phase'),
                        e => e.$eq('完成'),
                      )(
                        f => f('spec')('refund'),
                        e => e.$exists(false),
                      ),
                    )
                    .$group(
                      e => e.$fieldPath(f => f('spec')('inviterId')),
                      group =>
                        group('count', e => e.$sum(sum => sum.$literal(1))),
                    ),
                ),
              ),
          );

          list[0].table.forEach(item => {
            reportList.push([
              userInfoData[item.spec.userId.toHexString()]
                ? userInfoData[item.spec.userId.toHexString()].spec.phone
                : '--',
              userInfoData[item.spec.userId.toHexString()]
                ? userInfoData[item.spec.userId.toHexString()].spec.name
                : '--',
              userViewData[item.spec.userId.toHexString()]
                ? userViewData[item.spec.userId.toHexString()].count
                : 0,
              this.getNum(orderData, 'allPayment', item.spec.userId)
                ? Number(
                    (
                      this.getNum(orderData, 'allPayment', item.spec.userId) /
                      100
                    ).toFixed(2),
                  )
                : 0,
              this.getNum(orderData, 'orderPaymentNum', item.spec.userId),
              this.getNum(orderData, 'allPrice', item.spec.userId)
                ? Number(
                    (
                      this.getNum(orderData, 'allPrice', item.spec.userId) / 100
                    ).toFixed(2),
                  )
                : 0,
              this.getNum(orderData, 'allOrderNum', item.spec.userId),
              this.getNum(orderData, 'estimateProfit', item.spec.userId)
                ? Number(
                    (
                      this.getNum(
                        orderData,
                        'estimateProfit',
                        item.spec.userId,
                      ) / 100
                    ).toFixed(2),
                  )
                : 0,
              this.getNum(orderData, 'allProfit', item.spec.userId)
                ? Number(
                    (
                      this.getNum(orderData, 'allProfit', item.spec.userId) /
                      100
                    ).toFixed(2),
                  )
                : 0,
            ]);
          });
        }
        const filename = moment().format('YYYY-MM-DD') + '订单.xlsx'; //文件名称
        const wsname = 'Sheet1'; //Excel第一个sheet的名称
        const wb = xlsx.utils.book_new(),
          ws = xlsx.utils.aoa_to_sheet(reportList);
        xlsx.utils.book_append_sheet(wb, ws, wsname); //将数据添加到工作薄
        xlsx.writeFile(wb, filename); //导出Excel
      } else {
        this.loading = false;
        this.$message.error('暂无数据');
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.loading = false;
    }
  }
}

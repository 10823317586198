
import {Component, Vue} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {couponActivitys, coupons, users} from '../../../../resources';
import moment from 'moment';
import {ObjectId} from 'bson';
import {Coupon, CouponStatus} from '@/externals/MaxCI-Coupon-v1';
import lodash from 'lodash';
import {CouponActivity} from '@/externals/MaxCI-CouponActivity-v1';

@Component({
  name: 'couponDeatil',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private userNameData: any = {}; //用户手机号列表
  private detailId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );
  private searchData = {phone: '', status: ''};
  private total = 0;
  private list: Array<Coupon> = [];
  private listLoading = false;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private detailMsg: null | CouponActivity = null;
  async created() {
    this.updateList();
    this.detailMsg =
      (
        await couponActivitys.find(stage =>
          stage.$match(match =>
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            )(
              f => f('_id'),
              e => e.$eq(this.detailId),
            ),
          ),
        )
      ).find(() => true) ?? null;
  }
  //获取用户手机号
  private getUserPhone(id: ObjectId) {
    let phone = '';
    if (id) {
      phone = this.userNameData[id.toHexString()]
        ? this.userNameData[id.toHexString()].spec.phone
        : '';
    }
    return phone;
  }
  //获取优惠券状态
  private getStatus(row: Coupon) {
    let status = '';
    if (row.status?.phase === '已使用') {
      status = '已使用';
    } else {
      if (moment().isBefore(moment(row.spec.term.end))) {
        status = '未使用';
      } else {
        status = '已失效';
      }
    }
    return status;
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //使用时间
  private getUsedTime(status: CouponStatus) {
    let time = '--';
    if (status && status.phase === '已使用') {
      status.conditions.forEach(item => {
        if (item.type === '使用') {
          time = moment(item.creationTimestamp).format('YYYY-MM-DD HH:mm:ss');
        }
      });
    }
    return time;
  }
  //领取时间
  private getTime(time: string) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //获取列表
  private async updateList() {
    this.listLoading = true;
    try {
      //用手机号查询用户id
      let userId = null as null | ObjectId;
      if (this.searchData.phone) {
        const user = await users.find(stage =>
          stage.$match(match => {
            match(
              f => f('spec')('phone'),
              e => e.$eq(this.searchData.phone),
            )(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            return match;
          }),
        );
        if (user.length > 0) {
          userId = user[0]._id;
        }
      }
      const list = await coupons.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            )(
              f => f('spec')('activityId'),
              e => e.$eq(this.detailId),
            );
            if (this.searchData.phone) {
              match(
                f => f('spec')('userId'),
                e => e.$eq(userId as ObjectId),
              );
            }
            if (this.searchData.status === '已使用') {
              match(
                f => f('status')('phase'),
                e => e.$eq('已使用'),
              );
            }
            if (this.searchData.status === '未使用') {
              match(
                f => f('status')('phase'),
                e => e.$ne('已使用'),
              )(
                f => f('spec')('term')('end'),
                e => e.$gt(moment().toDate()),
              );
            }
            if (this.searchData.status === '已失效') {
              match(
                f => f('status')('phase'),
                e => e.$ne('已使用'),
              )(
                f => f('spec')('term')('end'),
                e => e.$lt(moment().toDate()),
              );
            }
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      let userIdData: Array<ObjectId> = [];
      list[0].table.forEach(item => {
        userIdData.push(item.spec.userId);
      });
      userIdData = Array.from(new Set(userIdData));
      const userData = await users.find(stage =>
        stage.$match(match => {
          match(
            f => f('_id'),
            e => e.$in(userIdData),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          );
          return match;
        }),
      );
      this.userNameData = lodash.zipObject(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        userData.map(v => v._id.toHexString()) as any,
        userData,
      );
      this.list = list[0].table;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'list',
      },
    });
  }
}

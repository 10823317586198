
import {Component, Vue} from 'vue-property-decorator';
import multiSelect from '@/components/multiSelect/index.vue';
import Pagination from '@/components/Pagination/index.vue';
import {orders, shops, userViews} from '../../../../resources';
import {ObjectId} from 'bson';
import {
  Order,
  MallOrderStatus,
  AgentOrderStatus,
  WithdrawalOrderStatus,
  OrderItem,
} from '@/externals/MaxCI-Order-v1';
import moment from 'moment';
import lodash from 'lodash';
import {EJSON} from 'bson';
import {Shop} from '@/externals/MaxCI-Shop-v1';

interface OrderShop extends Order {
  goodsMore: boolean;
}
@Component({
  name: 'distributionDetail',
  components: {
    multiSelect,
    Pagination,
  },
})
export default class extends Vue {
  private loading = false;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopInfoData: any = {}; //店铺信息
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private userInfoData: any = {}; //用户信息
  private total = 0;
  private list: Array<OrderShop> = [];
  private listLoading = true;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private time = '今';
  private searchData = {
    id: '',
    time: [moment().startOf('day').toDate(), moment().startOf('day').toDate()],
  };
  private role = localStorage.getItem('role');
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private shopAuthority: any = localStorage.getItem('shopAuthority')
    ? EJSON.parse(localStorage.getItem('shopAuthority') ?? '')
    : '';
  private shopSearchData = [] as Array<Shop>;
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private userId: ObjectId = ObjectId.createFromHexString(
    this.$route.query.id as string,
  );

  async created() {
    if (this.role === '应用') {
      //店铺搜索查询所有店铺
      const shopSearchData = [] as Array<Shop>;
      const shopCount = await shops.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            );
            return match;
          })
          .$facet(facet =>
            facet('count', countStage => countStage.$count('count')),
          ),
      );
      const count = shopCount[0].count[0]
        ? shopCount[0].count[0].count.valueOf()
        : 0;
      let i = 0;
      while (i < Math.ceil(count / 100)) {
        i++;
        const list = await shops.find(stage =>
          stage
            .$match(match => {
              match(
                f => f('spec')('applicationId'),
                e => e.$eq(this.applicationId),
              );
              return match;
            })
            .$facet(facet =>
              facet('table', tableStage =>
                tableStage
                  .$sort(sort => sort(f => f('_id'), '降序'))
                  .$skip((i - 1) * 100)
                  .$limit(100),
              ),
            ),
        );
        shopSearchData.push(...list[0].table);
      }
      this.shopSearchData = shopSearchData;
    } else {
      //店铺搜索查询所有店铺
      this.shopSearchData = await shops.find(stage =>
        stage.$match(match => {
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          )(
            f => f('_id'),
            e => e.$in(this.shopAuthority),
          );
          return match;
        }),
      );
    }
    this.updateList();
  }
  //获取总佣金
  private getCommission(items: Array<OrderItem>) {
    let price = 0;
    items.forEach(item => {
      if (item.snapshot.commissionRate) {
        price =
          price + item.snapshot.commissionRate * item.sku.price * item.count;
      }
    });
    return price;
  }
  //图片显示连接
  get downloadUrl() {
    return this.$store.state.pConfig.downloadUrl;
  }
  //获取店铺信息
  private getShopInfo(id: ObjectId, type: string) {
    let text = '';
    if (this.shopInfoData[id.toHexString()]) {
      if (type === 'name') {
        text = this.shopInfoData[id.toHexString()].spec.name;
      } else if (type === 'phone') {
        text = this.shopInfoData[id.toHexString()].spec.phone;
      }
    }
    return text;
  }
  //获取用户信息
  private getUserInfo(id: ObjectId) {
    let name = '';
    if (this.userInfoData[id.toHexString()]) {
      name = this.userInfoData[id.toHexString()].spec.name;
    }
    return name;
  }
  //商品查看更多
  private moreBtn(index: number) {
    this.list[index].goodsMore = !this.list[index].goodsMore;
  }
  //转换时间
  getTime(time: string | Date) {
    return moment(time).format('YYYY-MM-DD HH:mm:ss');
  }
  //选择自定义日期
  private changeTimeFrame() {
    this.checkList();
  }
  //获取订单状态
  private getStatus(
    status: MallOrderStatus | AgentOrderStatus | WithdrawalOrderStatus,
  ) {
    let statusText = '';
    if (status.phase === '确认') {
      statusText = '待支付';
    } else if (status.phase === '支付' || status.phase === '接单') {
      statusText = '待发货';
    } else if (status.phase === '取消') {
      statusText = '已取消';
    } else if (status.phase === '完成') {
      statusText = '已完成';
    } else if (status.phase === '退款') {
      statusText = '已退款';
    } else {
      statusText = '待收货';
    }
    return statusText;
  }
  //查询列表
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //查看详情
  private toDetail(id: ObjectId) {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'detail',
        id: id.toHexString(),
      },
    });
  }
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await orders.find(stage =>
        stage
          .$match(match =>
            match.$and(and => {
              and(query => {
                if (this.searchData.id) {
                  query(
                    f => f('_id'),
                    e =>
                      e.$eq(ObjectId.createFromHexString(this.searchData.id)),
                  );
                }
                query(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                )(
                  f => f('spec')('type'),
                  e => e.$eq('商城'),
                )(
                  f => f('spec')('inviterId'),
                  e => e.$eq(this.userId),
                );
                return query;
              });
              if (this.searchData.time && this.searchData.time.length > 0) {
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e => e.$gte(moment(this.searchData.time[0]).toDate()),
                  ),
                );
                and(query =>
                  query(
                    f => f('metadata')('creationTimestamp'),
                    e =>
                      e.$lte(
                        moment(this.searchData.time[1])
                          .add({day: 1})
                          .subtract({millisecond: 1})
                          .toDate(),
                      ),
                  ),
                );
              }
              return and;
            }),
          )
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      //查询店铺信息 用户信息
      let shopIdData: Array<ObjectId> = [];
      let userIdData: Array<ObjectId> = [];
      list[0].table.forEach(item => {
        if (item.spec.type === '商城' && item.spec.shopId) {
          shopIdData.push(item.spec.shopId);
        }
        userIdData.push(item.spec.userId);
      });
      //查询店铺信息
      shopIdData = Array.from(new Set(shopIdData));
      const shopData = await shops.find(stage =>
        stage.$match(match =>
          match(
            f => f('_id'),
            e => e.$in(shopIdData),
          )(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          ),
        ),
      );
      this.shopInfoData = lodash.zipObject(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        shopData.map(v => v._id.toHexString()) as any,
        shopData,
      );
      //用户信息
      userIdData = Array.from(new Set(userIdData));
      const userData = await userViews.find(stage =>
        stage.$match(match =>
          match(
            f => f('_id'),
            e => e.$in(userIdData),
          )(
            f => f('spec')('applicationId'),
            e =>
              e.$eq(
                ObjectId.createFromHexString(this.$route.params.applicationId),
              ),
          ),
        ),
      );
      this.userInfoData = lodash.zipObject(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        userData.map(v => v._id.toHexString()) as any,
        userData,
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      this.list = list[0].table.map(v => {
        return {
          ...v,
          goodsMore: false,
        };
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
  //商品属性值显示
  private getAttributes(attr: Array<string>) {
    let val = '';
    if (attr && attr.length > 0) {
      val = '/' + attr.join('/');
    }
    return val;
  }
  //返回
  private backBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'list'},
    });
  }
  //查看订单
  private checkOrder(id: ObjectId) {
    this.$router.push({
      path: '/application/' + this.$route.params.applicationId + '/order/index',
      query: {
        menu: '订单列表',
        type: 'detail',
        id: id.toHexString(),
      },
    });
  }
}

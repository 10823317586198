
import {Component, Vue, Watch} from 'vue-property-decorator';
import Pagination from '@/components/Pagination/index.vue';
import {CouponActivity} from '@/externals/MaxCI-CouponActivity-v1';
import {
  couponActivitys,
  coupons,
  users,
  couponDistributeRequests,
  applications,
  wechatACodeRequests,
  channels,
} from '../../../../resources';
import moment from 'moment';
import {ObjectId} from 'bson';
import {getUserId} from '../../../../api/publicMethod';
import {Channel} from '@/externals/MaxCI-Channel-v1';
import html2canvas from 'html2canvas';
import {EJSON} from 'bson';

interface CouponInfor extends CouponActivity {
  remainingCount: number;
}
@Component({
  name: 'couponList',
  components: {
    Pagination,
  },
})
export default class extends Vue {
  private validPhone = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/^1[23456789]\d{9}$/.test(value)) {
      callback(new Error('手机号格式不正确'));
    } else {
      callback();
    }
  };
  private validCount = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    rule: any,
    value: string,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    callback: (error?: Error) => void,
  ) => {
    if (!/^[1-9]\d*$/.test(value)) {
      callback(new Error('请输入大于0的正整数'));
    } else if (Number(value) > 500) {
      callback(new Error('数量不能大于500'));
    } else {
      callback();
    }
  };
  private appImg = '';
  private channelData = [] as Array<Channel>;
  private qrcode = '';
  private channelIndex = 0;
  private applicationName = '';
  private applicationLogo = '';
  private dialogCode = false;
  private operateId: null | ObjectId = null;
  private dialogOver = false; //结束提示
  private dialogDel = false; //删除提示
  private searchData = {
    name: '',
    status: '',
    type: '',
    couponType: '领取' as '领取' | '派发',
  };
  private total = 0;
  private list: Array<CouponInfor> = [];
  private listLoading = false;
  private listParams = {
    page: 1,
    limit: 10,
  };
  private applicationId = ObjectId.createFromHexString(
    this.$route.params.applicationId,
  );
  private formData: Array<{
    phone: string;
    count: null | number;
    error: string;
    distributeUserId?: null | ObjectId;
  }> = [];
  private dialogDistribute = false;
  private distributeFlag = false;
  private distributeStep = 1;
  private inforData: CouponInfor | null = null;
  async created() {
    //查询应用信息
    const application = (
      await applications.find(stage =>
        stage.$match(match => {
          match(
            f => f('_id'),
            e => e.$eq(this.applicationId),
          );
          return match;
        }),
      )
    ).find(() => true);
    this.applicationName = application?.spec.name ?? '';
    this.applicationLogo = application?.spec.logo ?? '';

    //查询微信渠道
    this.channelData = await channels.find(stage =>
      stage.$match(match =>
        match(
          f => f('spec')('applicationId'),
          e => e.$eq(this.applicationId),
        )(
          f => f('spec')('type'),
          e => e.$eq('微信小程序'),
        ),
      ),
    );
    this.updateList();
  }
  // 监听派发优惠券弹窗
  @Watch('dialogDistribute')
  async onUseThresholdChange(val: boolean) {
    if (!val) {
      this.distributeStep = 1;
      this.formData = [];
    }
  }
  //平台配置信息
  get platformConfig() {
    return this.$store.state.pConfig;
  }
  //派发优惠券 删除用户
  private removePeople(index: number) {
    this.formData.splice(index, 1);
  }
  //派发优惠券 新增用户
  private addPeople() {
    this.formData.push({
      phone: '',
      count: null,
      error: '',
      distributeUserId: null,
    });
  }
  //下一步
  private async nextBtn() {
    this.formData.forEach(item => {
      if (!item.phone) {
        item.error = '手机号不能为空';
      } else if (!/^1[23456789]\d{9}$/.test(item.phone)) {
        item.error = '手机号格式不正确';
      } else if (!item.count) {
        item.error = '数量不能为空';
      } else if (!/^[1-9]\d*$/.test(item.count.toString())) {
        item.error = '数量为大于0的正整数';
      } else if (Number(item.count) > 500) {
        item.error = '数量不能大于500';
      } else {
        item.error = '';
      }
    });
    for (const item of this.formData) {
      if (item.error) {
        return;
      }
    }
    const phoneData = [] as Array<string>;
    for (const item of this.formData) {
      console.log(phoneData);
      console.log(item.phone);
      if (phoneData.indexOf(item.phone) > -1) {
        item.error = '手机号不能重复';
        return;
      } else {
        item.error = '';
      }
      phoneData.push(item.phone);
    }
    for (const item of this.formData) {
      if (item.error) {
        return;
      }
    }
    for (const item of this.formData) {
      item.error = '';
      const user = await users.find(stage =>
        stage.$match(match => {
          match(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          )(
            f => f('spec')('phone'),
            e => e.$eq(item.phone),
          );
          return match;
        }),
      );
      if (user.length === 0) {
        item.error = '用户不存在';
        return;
      } else {
        item.distributeUserId = user[0]._id;
      }
    }
    this.distributeStep = 2;
  }
  //派发保存
  private async distributeSumbit() {
    try {
      this.distributeFlag = true;
      const data = this.formData.map(item => {
        return {
          spec: {
            count: Number(item.count),
            operatorId: getUserId(),
            activityId: this.operateId as ObjectId,
            userId: item.distributeUserId as ObjectId,
            applicationId: this.applicationId,
          },
        };
      });
      const couponDistributeRequest = await couponDistributeRequests.create(
        data,
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      );
      const formData = [] as Array<{
        phone: string;
        count: number | null;
        error: string;
      }>;
      let successNum = 0;
      couponDistributeRequest.forEach((item, index) => {
        if (item.status?.phase !== '成功') {
          formData.push({
            phone: this.formData[index].phone,
            count: this.formData[index].count,
            error: item.status?.conditions[0].message ?? '',
          });
        } else {
          successNum++;
        }
      });
      if (successNum === this.formData.length) {
        this.$message.success('派发成功');
        this.dialogDistribute = false;
        this.operateId = null;
        this.updateList();
      } else {
        this.distributeStep = 3;
        this.formData = formData;
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.distributeFlag = false;
    }
  }
  //切换渠道
  private changeChannel(index: number) {
    this.channelIndex = index;
    this.qrcode = '';
    this.checkCode();
  }
  //下载海报
  private async downPoster() {
    const canvasId = this.$refs.poster as HTMLElement;
    const canvas = await html2canvas(canvasId, {
      useCORS: true,
      allowTaint: false,
      scrollY: 0,
    });
    const url = canvas.toDataURL('image/jpeg');
    const a = document.createElement('a'); // 生成一个a元素
    const event = new MouseEvent('click'); // 创建一个单击事件
    a.download = this.inforData ? this.inforData.spec.name : '海报'; // 设置图片名称
    a.href = url; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  }

  //下载二维码
  private async downCode() {
    const a = document.createElement('a'); // 生成一个a元素
    const event = new MouseEvent('click'); // 创建一个单击事件
    a.download = this.inforData ? this.inforData.spec.name : '海报'; // 设置图片名称
    a.href = this.qrcode; // 将生成的URL设置为a.href属性
    a.dispatchEvent(event); // 触发a的单击事件
  }
  //查询二维码 index: "wx://back.self/page?page=首页&subpage=首页&params={\"shopId\":[{\"$oid\":\"605afb0f56582836ae76ca7f\"}]}"
  private checkCode() {
    const params = EJSON.stringify({activityId: [this.operateId]});
    const url = `wx://forward.self/page?page=领取优惠券&subpage=领取优惠券&params=${params}`;
    wechatACodeRequests
      .create(
        [
          {
            spec: {
              width: 1024,
              lineColor: '#000000',
              url: url,
              channelId: this.channelData[this.channelIndex]._id,
              isHyaline: true,
              applicationId: this.applicationId,
            },
          },
        ],
        {
          watch: {
            filter: filter =>
              filter(
                f => f('operationType'),
                e => e.$eq('update'),
              )(
                f => f('fullDocument')('status')('phase'),
                e => e.$exists(true),
              ),
          },
          fullResource: true,
        },
      )
      .then(res => {
        if (res[0].status?.phase === '成功') {
          if (res[0].status?.qrcode) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            const qrcode = res[0].status?.qrcode?.value(true) as any;
            const blob = new Blob([qrcode], {
              type: 'image/png',
            });
            const file = new FileReader();
            file.readAsDataURL(blob);
            file.onload = res => {
              this.qrcode = (res?.target?.result as string) ?? '';
            };
          }
        } else {
          this.$message.error(res[0].status?.conditions[0].message ?? '');
        }
      });
  }
  //推广
  private async codePopUps(row: CouponInfor) {
    this.operateId = row._id;
    this.inforData = row;
    if (this.channelData.length > 0) {
      await this.checkCode();
      this.dialogCode = true;
    } else {
      this.$message.error('暂无可用微信渠道');
    }
  }
  //派发弹窗
  private distributePopUps(row: CouponInfor) {
    this.operateId = row._id;
    this.inforData = row;
    this.dialogDistribute = true;
  }
  //结束
  overSumbit() {
    couponActivitys
      .update(
        filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.operateId as ObjectId),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        update =>
          update.$set(s =>
            s(
              f => f('spec')('receivableDate')('end'),
              moment().subtract({day: 1}).toDate(),
            ),
          ),
      )
      .then(res => {
        if (res.length > 0) {
          this.dialogOver = false;
          this.operateId = null;
          this.updateList();
          this.$message.success('已结束');
        }
      });
  }
  //结束提示
  overPopUps(id: ObjectId) {
    this.operateId = id;
    this.dialogOver = true;
  }
  //删除提示
  private delPopUps(id: ObjectId) {
    this.operateId = id;
    this.dialogDel = true;
  }
  //删除
  private async delSumbit() {
    try {
      if (this.operateId) {
        const couponActivity = await couponActivitys.delete(filter =>
          filter(
            f => f('_id'),
            e => e.$eq(this.operateId as ObjectId),
          )(
            f => f('spec')('applicationId'),
            e => e.$eq(this.applicationId),
          ),
        );
        if (couponActivity) {
          this.dialogDel = false;
          this.operateId = null;
          this.$message.success('已删除');
          this.updateList();
        }
      }
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      this.$message.error('网络异常，请稍后重试');
    }
  }
  //新增
  private addBtn() {
    this.$router.push({
      query: {menu: this.$route.query.menu, type: 'couponAdd'},
    });
  }
  //编辑
  private editBtn(id: ObjectId) {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'couponEdit',
        id: id.toHexString(),
      },
    });
  }
  //查看数据详情
  private to_detail(id: ObjectId) {
    this.$router.push({
      query: {
        menu: this.$route.query.menu,
        type: 'couponDetail',
        id: id.toHexString(),
      },
    });
  }
  //处理有限时间
  getExpiration(row: CouponInfor) {
    let time = null;
    if (row.spec.expiration.type === '固定日期') {
      time =
        moment(row.spec.expiration.begin).format('YYYY-MM-DD HH:mm') +
        '-' +
        moment(row.spec.expiration.end).format('YYYY-MM-DD HH:mm');
    } else if (row.spec.expiration.type === '领取当日') {
      time = '领取后当日起' + row.spec.expiration.day + '天内有效';
    } else if (row.spec.expiration.type === '领取次日') {
      time = '领取后次日起' + row.spec.expiration.day + '天内有效';
    }
    return time;
  }
  //处理活动时间
  private getActivityTime(start: Date, end: Date) {
    return (
      moment(start).format('YYYY-MM-DD HH:mm') +
      '-' +
      moment(end).format('YYYY-MM-DD HH:mm')
    );
  }
  //处理状态
  private getStatus(row: CouponInfor) {
    let status = '--';
    if (row.spec.type === '领取') {
      if (moment().isBefore(moment(row.spec.receivableDate.end))) {
        if (moment().isBefore(moment(row.spec.receivableDate.begin))) {
          status = '未开始';
        } else {
          status = '进行中';
        }
      } else {
        status = '已结束';
      }
    }

    return status;
  }
  //处理剩余券
  private remainingCoupons(row: CouponInfor) {
    let count = '--' as string | number;
    if (row.spec.type === '领取') {
      if (row.spec.quantity.daily === 0) {
        count = 0;
      } else {
        const diff =
          Number(moment(row.spec.receivableDate.end).diff(moment(), 'days')) +
          1;
        if (moment().isBefore(moment(row.spec.receivableDate.end))) {
          count = diff * row.spec.quantity.daily - row.remainingCount;
        } else {
          count = 0;
        }
      }
    }
    return count;
  }
  //搜索数据
  private checkList() {
    this.listParams.page = 1;
    this.updateList();
  }
  //获取列表
  private async updateList() {
    this.listLoading = true;
    try {
      const list = await couponActivitys.find(stage =>
        stage
          .$match(match => {
            match(
              f => f('spec')('applicationId'),
              e => e.$eq(this.applicationId),
            )(
              f => f('spec')('type'),
              e => e.$eq(this.searchData.couponType),
            );
            if (this.searchData.name) {
              match(
                f => f('spec')('name'),
                e => e.$regex(new RegExp(this.searchData.name)),
              );
            }
            if (this.searchData.type) {
              match(
                f => f('spec')('discount')('type'),
                e => e.$eq(this.searchData.type as '减免' | '折扣' | '赠送'),
              );
            }
            if (this.searchData.status === '未开始') {
              match(
                f => f('spec')('receivableDate')('begin'),
                e => e.$gt(moment().toDate()),
              );
            } else if (this.searchData.status === '已结束') {
              match(
                f => f('spec')('receivableDate')('end'),
                e => e.$lt(moment().toDate()),
              );
            } else if (this.searchData.status === '进行中') {
              match(
                f => f('spec')('receivableDate')('begin'),
                e => e.$lt(moment().toDate()),
              );
              match(
                f => f('spec')('receivableDate')('end'),
                e => e.$gt(moment().toDate()),
              );
            }
            return match;
          })
          .$facet(facet =>
            facet('table', tableStage =>
              tableStage
                .$sort(sort =>
                  sort(f => f('metadata')('creationTimestamp'), '降序'),
                )
                .$skip((this.listParams.page - 1) * this.listParams.limit)
                .$limit(this.listParams.limit),
            )('count', countStage => countStage.$count('count')),
          ),
      );
      this.total = list[0].count[0] ? list[0].count[0].count.valueOf() : 0;
      const couponActivity = list[0].table.map(v => {
        return {
          remainingCount: 0,
          ...v,
        };
      });
      couponActivity.forEach(item => {
        coupons
          .find(stage =>
            stage
              .$match(match => {
                match(
                  f => f('spec')('activityId'),
                  e => e.$eq(item._id),
                )(
                  f => f('spec')('applicationId'),
                  e => e.$eq(this.applicationId),
                );
                return match;
              })
              .$facet(facet =>
                facet('summary', summaryStage =>
                  summaryStage.$group(
                    e => e.$literal(null),
                    group =>
                      group('remainingCount', e =>
                        e.$sum(sum =>
                          sum.$cond(
                            _if =>
                              _if.$and(
                                e =>
                                  e.$gte(
                                    e =>
                                      e.$fieldPath(f =>
                                        f('metadata')('creationTimestamp'),
                                      ),
                                    e =>
                                      e.$dateFromString(e =>
                                        e.$literal(
                                          moment().startOf('day').toString(),
                                        ),
                                      ),
                                  ),
                                e =>
                                  e.$lte(
                                    e =>
                                      e.$fieldPath(f =>
                                        f('metadata')('creationTimestamp'),
                                      ),
                                    e =>
                                      e.$dateFromString(e =>
                                        e.$literal(
                                          moment().endOf('day').toString(),
                                        ),
                                      ),
                                  ),
                              ),
                            _then => _then.$literal(1),
                            _else => _else.$literal(0),
                          ),
                        ),
                      ),
                  ),
                ),
              ),
          )
          .then(res => {
            if (res[0].summary.length > 0) {
              item.remainingCount = res[0].summary[0].remainingCount;
            }
          });
      });
      this.list = couponActivity;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      console.log(e);
      this.$message.error('网络异常，请稍后重试');
    } finally {
      this.listLoading = false;
    }
  }
}

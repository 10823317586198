
import {Component, Vue} from 'vue-property-decorator';
import couponList from './list.vue';
import couponAdd from './add.vue';
import couponEdit from './edit.vue';
import couponDetail from './detail.vue';
@Component({
  name: 'coupon',
  components: {
    couponList,
    couponAdd,
    couponEdit,
    couponDetail,
  },
})
export default class extends Vue {
  private type = '';
  created() {
    this.type = this.$route.query.type
      ? (this.$route.query.type as string)
      : 'list';
  }
}
